













































import Vue from 'vue';
import Component, {mixins} from 'vue-class-component';
import { placeModule, reviewModule, ticketModule } from '@/store';
import { Prop } from 'vue-property-decorator';
import { auth } from '@/shared/auth';
import * as firebase from 'firebase/app';
import dataFormat from '@/shared/utils/dataFormat';
import Swiper from 'swiper';

@Component
export default class ReviewInfo extends mixins(dataFormat) {
  @Prop()
  public reviews!: any;
  @Prop()
  public placeId!: any;
  public swiper: any = null;
  public mounted() {
    this.activeSwiper();
  }
  public updated() {
    this.activeSwiper();
  }
  public activeSwiper() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
    const swiper = new Swiper('.review-list .swiper-container', {
      slidesPerView: 'auto',
      observer: true,
      observeParents: true,
      spaceBetween: -10,
    });
  }

  get reviewMeta() {
    return reviewModule.userReviewMeta;
  }

  public checkLogin() {
    if (auth.getAccessUserId() === '') {
      this.$modal.show('login');
    } else {
      this.eventClick();
      this.$router.push({ name: 'review-write', query: { placeId: this.placeId} });
    }
  }

  public eventClick() {
    const eventName = 'click';
    const eventParams: any = {
      button_type: 'write_review',
      type: null,
      id: null,
      name: null
    }

    if (this.$route.name === 'place') {
      eventParams.type = 'place',
      eventParams.id = this.place.id.toString(),
      eventParams.name = this.place.name
    } else if (this.$route.name === 'product') {
      eventParams.type = 'product',
      eventParams.id = this.product.id.toString(),
      eventParams.name = this.product.title
    }
    firebase.analytics().logEvent(eventName, eventParams);
  }
  public reviewMore() {
    if (this.place.review_count === 0) {
      return;
    }
    this.$router.push({ path: `/review/place/${this.placeId}` })
  }
  public reviewDetail(id: any) {
    this.$router.push({ name: 'detailReview', params: { id: `${id}` } })
  }

  get place(): any {
    return placeModule.placeInfoData;
  }
  get product(): any {
    return ticketModule.productInfo;
  }
}
